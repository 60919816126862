<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="标题">
                <a-input placeholder="请输入标题" v-model="queryParam.title" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="用户名称">
                <a-input placeholder="请输入用户名称" v-model="queryParam.userName" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="手机号码">
                <a-input placeholder="请输入手机号码" v-model="queryParam.telephone" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="问题分类">
                  <a-select
                    show-search
                    placeholder="请选择"
                    allow-clear
                    :options="classifyList"
                    option-filter-prop="children"
                    @change="handleChangClass"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="网格卡点">
                  <a-select
                    show-search
                    placeholder="请选择"
                    allow-clear
                    :options="pointList"
                    option-filter-prop="children"
                    @change="handleChangeGrid"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="状态">
                  <a-select
                    v-model="queryParam.status"
                    placeholder="状态"
                    allow-clear
                  >
                    <a-select-option :key="-1" :value="-1">请选择</a-select-option>
                    <a-select-option :key="0" :value="0">未处理</a-select-option>
                    <a-select-option :key="1" :value="1">已处理</a-select-option>
                    <a-select-option :key="2" :value="2">暂缓处理</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="上报时间">
                  <a-range-picker
                    format="YYYY-MM-DD"
                    valueFormat="YYYY-MM-DD"
                    v-model="searcDate"
                    @change="changeTime"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="default"
        rowKey="id"
        :columns="columns"
        :data="loadData"
      >
        <span slot="realName" slot-scope="text, record">
          {{ record.realName }}({{ record.telephone }})
        </span>
        <span slot="status" slot-scope="text, record">
          <a-tag color="#f50" v-if="record.status === 0">未处理</a-tag>
          <a-tag color="#87d068" v-else-if="record.status === 1">已处理</a-tag>
          <a-tag color="#595959" v-else>暂缓处理</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="gotoInfo(record.id)">查看</a>
          <a-divider type="vertical" />
          <a @click="gotoDealInfo(record)">处理</a>
        </span>
      </s-table>
      <deal-event @ok="handleOk" ref="dealevent"></deal-event>
      <see-event @ok="handleOk" ref="seeevent"></see-event>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import STree from '@/components/Tree/Tree'
import { STable, Ellipsis } from '@/components'
import DealEvent from '@/views/grid/components/DealEvent'
import SeeEvent from '@/views/grid/components/SeeEvent'
import { gridCondition, gridEventList } from '@/api/gridevent'
const columns = [
  {
    title: '编号',
    dataIndex: 'id',
    width: '100px'
  },
  {
    title: '标题',
    dataIndex: 'title'
  },
  {
    title: '网格员',
    dataIndex: 'realName',
    scopedSlots: { customRender: 'realName' }
  },
  {
    title: '问题分类',
    dataIndex: 'classifyTitle'
  },
  {
    title: '网格卡点',
    dataIndex: 'gridName'
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '上报时间',
    dataIndex: 'createTime'
  },
  {
    title: '操作',
    width: '120px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'EventList',
  components: {
    STable,
    Ellipsis,
    STree,
    DealEvent,
    SeeEvent
  },
  data () {
    this.columns = columns
    return {
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        title: '',
        status: -1,
        userName: '',
        telephone: '',
        classifyId: 0,
        gridId: 0,
        startTime: '',
        endTime: ''
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return gridEventList(requestParameters)
          .then(res => {
            return res.result
          })
      },
      searcDate: [],
      pointList: [],
      classifyList: []
    }
  },
  filters: {
  },
  created () {
    this.gridConditionApi()
  },
  watch: {
    $route (val) {
      if (val.name === 'CommunityActivitiesList') {
        this.queryParam = {
          title: '',
          status: -1,
          userName: '',
          telephone: '',
          classifyId: 0,
          gridId: 0,
          startTime: '',
          endTime: ''
        }
        this.searcDate = []
        this.$refs.table.refresh(true)
      }
    }
  },
  methods: {
    resetSearchForm () {
      this.queryParam = {
        title: '',
        status: -1,
        userName: '',
        telephone: '',
        classifyId: 0,
        gridId: 0,
        startTime: '',
        endTime: ''
      }
      this.searcDate = []
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    changeTime (e) {
      this.queryParam.startTime = e[0] ? e[0] : ''
      this.queryParam.endTime = e[1] ? e[1] : ''
      this.searcDate = e
      console.log('changeTime', e)
    },
    gotoInfo (keyId) {
      this.$refs.seeevent.dataInfoApi(keyId)
    },
    gotoDealInfo (data) {
      this.$refs.dealevent.ininData(data)
    },
    handleOk () {
      // 新增/修改 成功时，重载列表
      this.$refs.table.refresh()
    },
    gridConditionApi () {
      const _this = this
      gridCondition().then((res) => {
        if (res.errorCode === 0) {
          _this.pointList = res.result.pointList
          _this.classifyList = res.result.classifyList
          console.log(res)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    handleChangeGrid (e) {
      console.log(e)
      if (e === undefined || e === 0) {
        this.queryParam.gridId = 0
      } else {
        this.queryParam.gridId = e
      }
    },
    handleChangClass (e) {
      console.log(e)
      if (e === undefined || e === 0) {
        this.queryParam.classifyId = 0
      } else {
        this.queryParam.classifyId = e
      }
    }
  }
}
</script>
